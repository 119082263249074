<template>
	<div class="addTemplate">
		<el-card class="box-card">
			<div class="infortion">模板信息</div>
			<!-- 表单 -->
			<el-form :model="ruleForm" ref="ruleForm" label-width="140px" class="demo-ruleForm">
				<el-form-item
					label="运费模板名称"
					prop="templateName"
					:rules="[{required: true,message: '请填写运费模板名称',trigger: 'blur'}]"
				>
					<el-input v-model="ruleForm.templateName" style="width:250px;"></el-input>
				</el-form-item>

				<el-form-item label="运费设置">
					<span class="lml_tips">
						除指定配送区域,其他区域采用
						<span style="color:red">全国默认运费</span>
					</span>

					<el-table :data="ruleForm.templateLineList" border style="width: 100%;margin-top:20px">
						<el-table-column label="配送区域" width="500">
							<template slot-scope="scope">
								<el-tag
									v-for="(tag,index1) in scope.row.areaNames"
									:key="index1"
									style="margin:0 10px 10px 10px"
									:disable-transitions="false"
								>{{tag}}</el-tag>
								<span v-if="scope.row.haveMore" style="margin-right:5px;">等</span>
								<span class="addClass" @click="addArefun(scope.row,1)" v-if="scope.row.iShows">查看详情</span>
							</template>
						</el-table-column>

						<el-table-column prop="areaPrice" label="首件(个)" width="105">
							<template slot-scope="scope">
								<input
									v-model="scope.row.startingAmount"
									style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
									oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
									type="number"
								/>
							</template>
						</el-table-column>

						<el-table-column prop="areaPrice" label="运费(元)" width="105">
							<template slot-scope="scope">
								<input
									v-model="scope.row.startingPrice"
									style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
									@keydown="handleInput2"
									type="number"
								/>
							</template>
						</el-table-column>

						<!-- <el-table-column prop="areaPrice" label="续件(个)" width="105">
							<template slot-scope="scope">
								<input
									v-model="scope.row.additionAmount"
									style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
									oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
									type="number"
								/>
							</template>
						</el-table-column>

						<el-table-column prop="areaPrice" label="续费(元)" width="105">
							<template slot-scope="scope">
								<input
									v-model="scope.row.additionPrice"
									style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
									@keydown="handleInput2"
									type="number"
								/>
							</template>
						</el-table-column>-->
						<el-table-column label="操作">
							<template slot-scope="scope">
								<span
									style="color:red;cursor: pointer;"
									@click="delectFright(scope.row,scope.$index)"
									v-if="scope.row.iShows"
								>删除</span>
							</template>
						</el-table-column>
					</el-table>
					<div class="addArea" @click="addArefun({},1)">添加区域运费</div>
				</el-form-item>

				<!-- 不配送区域 -->
				<el-form-item label="不配送区域">
					<el-table :data="ruleForm.templateLineList1" border style="width: 100%;">
						<el-table-column label="不配送区域名称">
							<template slot-scope="scope">
								<el-tag
									v-for="(tag,index1) in scope.row.areaNames"
									:key="index1"
									style="margin:0 10px 10px 10px"
									:disable-transitions="false"
								>{{tag}}</el-tag>
								<span v-if="scope.row.haveMore" style="margin-right:5px;">等</span>
								<span class="addClass" @click="addArefun(scope.row,2)" v-if="scope.row.iShows">查看详情</span>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</el-form>
		</el-card>

		<!-- 选择包邮地区 -->
		<el-dialog :title="titleShows" :visible.sync="selectShipShow" width="900px" v-if="selectShipShow">
			<select-ship
				:defaultData="showAreaIds"
				@getCheckedArea="getCheckedArea"
				:disableArr="disableArr"
			></select-ship>
		</el-dialog>

		<div style="height:60px;"></div>
		<div class="footer-tools">
			<el-button style="width:200px;margin-right: 10px;" @click="canselBtn">取 消</el-button>
			<el-button
				type="primary"
				style="width:200px"
				@click="saveTemptet('ruleForm')"
				:loading="saveLoading"
			>保 存</el-button>
		</div>
	</div>
</template>

<script>
import selectShip from '../../market/discountActivity/selectShipDialog.vue';
import util from '@/utils/util.js'
import addressList from '@/assets/js/address'
import {
	freightTemplateSave,
	freightTemplateinfo
} from '@/api/goods'
export default {
	components: {
		selectShip
	},
	data () {
		return {
			titleShows: '',     //选择区域弹窗标题
			ruleForm: {
				id: null,
				templateName: '',
				IsFree: false,
				templateLineList: [{
					templateType: '1',    //模板类型，1=全国默认运费，2=指定区域
					areaNames: ['全国默认运费'],    //配送区域
					areaCodeList: [],
					iShows: false,
					startingAmount: 1,
					startingPrice: 0,
					additionAmount: 1,
					additionPrice: 0,
					haveMore: false,
				}],
				templateLineList1: [{
					areaNames: [],    //配送区域
					areaCodeList: [],
					iShows: true,
					startingAmount: 1,
					startingPrice: 0,
					additionAmount: 1,
					additionPrice: 0,
					haveMore: false,
				}],  //不配送区域数据
			},
			recordData: {},
			saveLoading: false,     //保存按钮限制
			selecttype: '',     //选择类型，1=配送区域，2=不配送区域
			selectShipShow: false,  //选择区域弹窗开关
			showAreaIds: [],       //已选中id集合
			disableArr: [],     //禁用列表
		}
	},
	beforeMount () {
		this.ruleForm.id = this.$route.query.templateId == 0 ? '' : this.$route.query.templateId;
		this.getDat();
	},
	methods: {
		//选择区域选中回调
		getCheckedArea (data) {
			//处理区域数据
			let obj = {
				templateType: '2',
				areaNames: [],
				areaCodeList: [],
				uid: this.guid(),
				iShows: true,
				startingAmount: 1,
				startingPrice: null,
				additionAmount: 1,
				additionPrice: 0,
				haveMore: false
			}
			data.map(item => {
				if (!item.children) {
					obj.areaCodeList.push(item.id);
				} else if (item.children && !item.children.length) {
					obj.areaCodeList.push(item.id);
				}
				if (obj.areaNames.length < 3) {
					obj.areaNames.push(item.name);
				} else {
					obj.haveMore = true;
				}
			})
			//更新/添加区域
			if (this.recordData.uid) {
				this.ruleForm.templateLineList = this.ruleForm.templateLineList.map(its => {
					if (its.uid == this.recordData.uid) {
						its.areaNames = obj.areaNames;
						its.areaCodeList = obj.areaCodeList;
					}
					return its
				})
			} else if (data.length) {
				if (this.selecttype == 1) {
					this.ruleForm.templateLineList.push(obj)
				} else {
					this.ruleForm.templateLineList1[0].areaNames = obj.areaNames;
                    this.ruleForm.templateLineList1[0].areaCodeList = obj.areaCodeList;
                    //不配送区域会直接将配送区域删掉
                    this.ruleForm.templateLineList1[0].areaCodeList.map(code =>{
                        for (let index = 0; index < this.ruleForm.templateLineList.length; index++) {
                            const item = this.ruleForm.templateLineList[index];
                            item.areaCodeList.map((item2,index2) =>{
                                if (code == item2) {
                                    item.areaCodeList.splice(index2, 1);
                                }
                            })
                            if (item.areaCodeList.length == 0 && index != 0) {
                                this.ruleForm.templateLineList.splice(index, 1);
                                index--;
                            }
                        }
                        
                    })
				}
            }
			this.selectShipShow = false;
		},

		// 删除运费
		delectFright (record, index) {
			this.ruleForm.templateLineList.splice(index, 1)
		},

		handleInput2 (e) {
			// 通过正则过滤小数点后两位
			e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

		},

		//初始化数据
		async getDat () {
			this.loading = true;
			try {
				if (this.ruleForm.id > 0) {
					let result = await freightTemplateinfo({
						id: this.ruleForm.id
					});

					this.ruleForm.templateName = result.data.templateName;
					const countyList = addressList.areaList.county_list;

					if (result.data.templateLineList) {
						result.data.templateLineList.map((item, index) => {
							item.areaNames = [];
							if (index == 0) {
								item.areaNames.push('全国默认运费');
								item.iShows = false;
							} else {
								item.uid = this.guid();
								item.iShows = true;
								item.areaCodeList.map(areaCode => {
									if (item.areaNames.length < 3) {
										item.areaNames.push(countyList[areaCode]);
									}
								})

							}
						})

						this.ruleForm.templateLineList = result.data.templateLineList;
						this.ruleForm.templateLineList1[0].areaCodeList = result.data.limitedAreaCodeList;
					}
					if (result.data.limitedAreaCodeList) {
						this.ruleForm.templateLineList1[0].areaCodeList = result.data.limitedAreaCodeList;
						result.data.limitedAreaCodeList.map(item => {
							if (this.ruleForm.templateLineList1[0].areaNames.length < 3) {
								this.ruleForm.templateLineList1[0].areaNames.push(countyList[item]);
							} else {
								this.ruleForm.templateLineList1[0].haveMore = true;
								return false;
							}
						})
					}

					if (this.$route.query.type) {
						this.ruleForm.id = null;
					}

				} else {
					this.formatTemplateLineList1();
				}

			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},
		//初始化不配送区域名称
		formatTemplateLineList1 (ids) {
			console.log(ids);
			var _this = this;
			let addressList = util.getAddressList();
			addressList.map(address => {
				if (address.id >= 710000 && address.id <= 820000) {
					address.children.map(addressc => {
						addressc.children.map(addresscc => {
							_this.ruleForm.templateLineList1[0].areaCodeList.push(addresscc.id);
							if (ids) {
								if (ids.indexOf(addresscc.id) == -1) {
									return false;
								}
							}
							if (_this.ruleForm.templateLineList1[0].areaNames.length < 3) {
								_this.ruleForm.templateLineList1[0].areaNames.push(addresscc.name);
							} else {
								_this.ruleForm.templateLineList1[0].haveMore = true;
							}
						});
					})
				}
			})
		},
		// 数组去重
		unique (arr) {

			const res = new Map();
			return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
		},
		// 保存运费模板
		saveTemptet (formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {

					this.saveLoading = true;
					try {

						let op = false;

						this.ruleForm.templateLineList.map(item => {
							if (!item.startingAmount) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '首件不能为空!'
								});
								this.saveLoading = false
								op = true;
								return false;
							} else if (!item.startingPrice && item.startingPrice != 0) {
								console.log();
								this.$message({
									showClose: true,
									type: 'error',
									message: '运费不能为空!'
								});
								this.saveLoading = false
								op = true;
								return false;
							}
							// else if (!item.additionAmount) {
							// 	this.$message({
							// 		showClose: true,
							// 		type: 'error',
							// 		message: '续件不能为空!'
							// 	});
							// 	this.saveLoading = false
							// 	op = true;
							// 	return false;
							// } else if (!item.additionPrice && item.additionPrice != 0) {
							// 	this.$message({
							// 		showClose: true,
							// 		type: 'error',
							// 		message: '续费不能为空!'
							// 	});
							// 	this.saveLoading = false
							// 	op = true;
							// 	return false;
							// }
						})

						if (op) {
							return false;
						}


						let data = {
							...this.ruleForm
						}
						data.limitedAreaCodeList = data.templateLineList1[0].areaCodeList;

						let result = await freightTemplateSave(data);
						if (result.success) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功'
							});

							this.saveLoading = false;
							this.canselBtn();
						} else {
							this.saveLoading = false;
							this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg
							});
						}

					} catch (e) {
						console.log(e)
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},

		// 添加区域
		async addArefun (record, type) {
			if (type == 1) {
				this.titleShows = '配送区域'
			} else {
				this.titleShows = '不配送区域'
			}
			this.selecttype = type;
			this.recordData = record;
			this.showAreaIds = (record.areaCodeList && record.areaCodeList.length) ? record.areaCodeList : []
			this.formatDisableArr(record, type);
			this.selectShipShow = true;
		},

		// 取消事件
		canselBtn () {
			this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
				(item) => {
					return item.path != this.$route.path;
				}
			);
			this.$router.push({
				path: '/setting/shippingTemplates'
			});
		},
		guid () {
			var a = function () {
				return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
			};
			return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
		},
		//处理不可选择区域
		formatDisableArr (row, type) {
			let disableArr = [];
			if (type == 1) {
                this.ruleForm.templateLineList.map(item => {
                    if (row.uid != item.uid) {
                        disableArr = disableArr.concat(item.areaCodeList);
                    }
                })
				this.ruleForm.templateLineList1.map(item => {
					disableArr = disableArr.concat(item.areaCodeList);
                })
                this.disableArr = disableArr;
			}else{
                this.disableArr = [];
            }
		}
	}
}
</script>

<style lang="less">
.addTemplate {
	.addClass {
		width: 80px;
		height: 30px;
		line-height: 30px;
		color: #409eff;
		text-align: center;
		background-color: #ecf5ff;
		border: 1px solid #d9ecff;
		border-radius: 4px;
		display: inline-block;
		cursor: pointer;
	}

	.infortion {
		margin-bottom: 20px;
		padding: 10px 0;
		border-bottom: 1px solid #ccc;
	}

	.box-card {
		width: 90%;
	}

	.lml_tips {
		color: #999;
		font-size: 14px;
		padding-left: 15px;
	}

	.addArea {
		color: blue;
		font-size: 14px;
		width: 100%;
		border: 1px solid #ddd;
		padding: 5px 15px;
		cursor: pointer;
	}

	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.number__input {
		border: 1px solid #ccc;
	}

	.footer-tools {
		width: 100%;
		background: #fff;
		position: fixed;
		bottom: 0;
		background: #fff;
		padding: 10px 0;
		text-align: center;
	}
}
</style>
